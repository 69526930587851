var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "bg-white p-4 my-4 rounded-lg shadow-sm"
  }, [_c('label', [_c('span', {
    staticClass: "text-capitalize cursor-pointer text-gray-900 font-semibold",
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v(" Discount")]), _vm._v(" "), _c('i', {
    staticClass: "fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"
  }), _vm._v(" "), _c('span', {
    staticClass: "text-gray-500 font-normal capitalize"
  }, [_vm._v(" Edit ")])]), _c('h4', {
    staticClass: "text-xl capitalize"
  }, [_vm._v("Edit Discount")])]), _c('div', {
    staticClass: "tab-container"
  }, [_c('div', {
    staticClass: "tab-header flex items-center space-x-4 bg-white rounded-lg shadow-sm py-3 px-4 mb-4"
  }, [_c('div', {
    class: ['tab-item flex items-center', {
      'text-blue-500 font-semibold': _vm.currentTab === 1
    }]
  }, [_c('span', {
    class: ['font-bold rounded-full border p-1.5 mr-2 text-center w-9 h-9', {
      'border-custom': _vm.currentTab === 1
    }]
  }, [_vm._v("1")]), _vm._v(" General ")]), _c('span', {
    staticClass: "text-gray-400"
  }, [_vm._v("»")]), _c('div', {
    class: ['tab-item flex items-center', {
      'text-blue-500 font-semibold': _vm.currentTab === 2
    }]
  }, [_c('span', {
    class: ['font-bold rounded-full border p-1.5 mr-2 text-center w-9 h-9', {
      'border-custom': _vm.currentTab === 2
    }]
  }, [_vm._v("2")]), _vm._v(" Platform ")]), _c('span', {
    staticClass: "text-gray-400"
  }, [_vm._v("»")]), _c('div', {
    class: ['tab-item flex items-center', {
      'text-blue-500 font-semibold': _vm.currentTab === 3
    }]
  }, [_c('span', {
    class: ['font-bold rounded-full border p-1.5 mr-2 text-center w-9 h-9', {
      'border-custom': _vm.currentTab === 3
    }]
  }, [_vm._v("3")]), _vm._v(" Detail ")])]), _c('div', {
    staticClass: "tab-content mt-6 px-4 py-4"
  }, [_vm.currentTab === 1 ? _c('div', [_c('h5', {
    staticClass: "text-gray-900 font-bold text-xl"
  }, [_vm._v("Edit Discount")]), _c('div', {
    staticClass: "flex-col w-1/2 mt-3 pr-4"
  }, [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold mb-3 block"
  }, [_vm._v("Discount Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    class: true == true ? 'opacity-60 bg-gray-50 py-2.5 px-2 border rounded-lg w-full' : 'bg-gray-50 py-2.5 px-2 border rounded-lg w-full',
    attrs: {
      "id": "discount-Name",
      "placeholder": "Enter Discount Name",
      "requiredtype": "text",
      "disabled": true
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Campaign Name")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Type Campaign",
      "open-direction": "bottom",
      "options": _vm.campaignSearchOptions,
      "searchable": true,
      "loading": _vm.isCampaignSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": false,
      "disabled": true
    },
    on: {
      "search-change": _vm.campaignFind
    },
    model: {
      value: _vm.form.campaign,
      callback: function ($$v) {
        _vm.$set(_vm.form, "campaign", $$v);
      },
      expression: "form.campaign"
    }
  }), _c('DatetimeCustom', {
    staticClass: "mt-3",
    attrs: {
      "timeOnlyHours": true,
      "label": 'Valid From'
    },
    model: {
      value: _vm.form.valid_from,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid_from", $$v);
      },
      expression: "form.valid_from"
    }
  }), _c('DatetimeCustom', {
    staticClass: "mt-3",
    attrs: {
      "timeOnlyHours": true,
      "label": 'Valid To'
    },
    model: {
      value: _vm.form.valid_to,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid_to", $$v);
      },
      expression: "form.valid_to"
    }
  }), _c('label', {
    staticClass: "text-gray-600 text-sm font-semibold mb-3 mt-3 block"
  }, [_vm._v("Partner Participation (Optional)")]), _c('SelectType', {
    attrs: {
      "options": _vm.partnerParticipations,
      "disabled": true,
      "default-text": 'Enter Partner Participation'
    },
    model: {
      value: _vm.form.partnerParticipation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "partnerParticipation", $$v);
      },
      expression: "form.partnerParticipation"
    }
  }), _c('label', {
    staticClass: "text-gray-600 text-sm font-semibold mb-3 mt-3 block"
  }, [_vm._v("Sales Recognition (Optional)")]), _c('SelectType', {
    attrs: {
      "options": _vm.salesRecognitions,
      "disabled": true,
      "default-text": 'Enter Sales Recognition'
    },
    model: {
      value: _vm.form.salesRecognition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "salesRecognition", $$v);
      },
      expression: "form.salesRecognition"
    }
  }), _c('label', {
    staticClass: "text-gray-600 text-sm font-semibold mb-3 mt-3 block"
  }, [_vm._v("Vendor Participation (Optional)")]), _c('SelectType', {
    attrs: {
      "options": _vm.vendorParticipations,
      "disabled": true,
      "default-text": 'Enter Sales Recognition'
    },
    model: {
      value: _vm.form.vendorParticipation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vendorParticipation", $$v);
      },
      expression: "form.vendorParticipation"
    }
  }), _c('label', {
    staticClass: "text-gray-600 text-sm font-semibold mb-3 mt-3 block"
  }, [_vm._v("Discount Activation Status")]), _c('div', [_c('button', {
    class: ['w-12 h-6 button-active-inactive rounded-full relative transition-colors duration-200 focus:outline-none', _vm.form.is_active === true ? 'bg-blue-700' : 'bg-gray-300'],
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.toggleActive
    }
  }, [_c('span', {
    class: ['w-5 h-5 bg-white rounded-full shadow transform transition-transform duration-200', _vm.form.is_active === true ? 'translate-x-6' : 'translate-x-0']
  })])])], 1)]) : _vm._e(), _vm.currentTab === 2 ? _c('div', {
    key: _vm.currentTab
  }, [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold mb-3 mt-3 block"
  }, [_vm._v("Platform")]), _c('div', {
    staticClass: "w-1/2"
  }, [_c('SelectType', {
    attrs: {
      "options": _vm.platformsOptions,
      "disabled": true,
      "default-text": 'Select Platform'
    },
    model: {
      value: _vm.form.platforms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "platforms", $$v);
      },
      expression: "form.platforms"
    }
  })], 1), _vm.form.platforms === 1 || _vm.form.platforms === 2 ? _c('div', [_c('div', {
    staticClass: "w-1/2 mt-3"
  }, [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold mb-3 mt-3 block"
  }, [_vm._v("Pricing Scheme")]), _c('SelectType', {
    attrs: {
      "options": _vm.pricingSchemeOptions,
      "default-text": 'Pricing Scheme',
      "disabled": true
    },
    model: {
      value: _vm.form.pricingScheme,
      callback: function ($$v) {
        _vm.$set(_vm.form, "pricingScheme", $$v);
      },
      expression: "form.pricingScheme"
    }
  })], 1)]) : _vm._e()]) : _vm._e(), _vm.currentTab === 3 ? _c('div', [_c('h4', {
    staticClass: "text-xl font-semibold"
  }, [_vm._v("Edit New Discount")]), _vm.form.platforms === 1 || _vm.form.platforms === 2 ? _c('div', {
    staticClass: "w-1/2"
  }, [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Discount Type")]), _c('SelectType', {
    attrs: {
      "options": _vm.discountTypes,
      "default-text": 'Select Discount Type',
      "disabled": true
    },
    model: {
      value: _vm.form.discountType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "discountType", $$v);
      },
      expression: "form.discountType"
    }
  }), this.form.platforms === 1 ? _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Payment Gateway")]), _c('MultiselectCustom', {
    attrs: {
      "options": _vm.paymentGatewaysOptions,
      "isSearchEnabled": true,
      "disabled": true,
      "default-text": 'Select Discount Rule ',
      "isMultiSelectActive": true
    },
    model: {
      value: _vm.form.paymentGateways,
      callback: function ($$v) {
        _vm.$set(_vm.form, "paymentGateways", $$v);
      },
      expression: "form.paymentGateways"
    }
  })], 1) : _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Payment Gateway")]), _c('MultiselectCustom', {
    attrs: {
      "options": _vm.paymentGatewaysOptions,
      "isSearchEnabled": true,
      "default-text": 'Select Discount Rule ',
      "isMultiSelectActive": true,
      "disabled": true
    },
    model: {
      value: _vm.form.paymentGateways,
      callback: function ($$v) {
        _vm.$set(_vm.form, "paymentGateways", $$v);
      },
      expression: "form.paymentGateways"
    }
  })], 1), _c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Discount Rule")]), _c('SelectType', {
    attrs: {
      "options": _vm.discountRuleMobileOptions,
      "disabled": _vm.form.pricingScheme === 2,
      "default-text": 'Select Discount Rule '
    },
    model: {
      value: _vm.form.discountRuleMobile,
      callback: function ($$v) {
        _vm.$set(_vm.form, "discountRuleMobile", $$v);
      },
      expression: "form.discountRuleMobile"
    }
  }), this.form.discountRuleMobile === 3 && this.form.pricingScheme === 1 && this.form.platforms === 1 ? _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Discount Tier")]), _c('multiselect', {
    attrs: {
      "label": "tier_code",
      "track-by": "tier_code",
      "placeholder": "Ios Tier",
      "open-direction": "bottom",
      "options": _vm.iosTierSearchOptions,
      "searchable": true,
      "close-on-select": true,
      "multiple": false
    },
    on: {
      "search-change": _vm.iosTierFind
    },
    model: {
      value: _vm.form.ios_tier,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ios_tier", $$v);
      },
      expression: "form.ios_tier"
    }
  })], 1) : _vm._e(), this.form.discountRuleMobile === 3 && this.form.pricingScheme === 1 && this.form.platforms === 2 ? _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Discount Tier")]), _c('multiselect', {
    attrs: {
      "label": "tier_code",
      "track-by": "tier_code",
      "placeholder": "Android Tier",
      "open-direction": "bottom",
      "options": _vm.androidSearchOptions,
      "searchable": true,
      "close-on-select": true,
      "multiple": false
    },
    on: {
      "search-change": _vm.androidTierTierFind
    },
    model: {
      value: _vm.form.android_tier,
      callback: function ($$v) {
        _vm.$set(_vm.form, "android_tier", $$v);
      },
      expression: "form.android_tier"
    }
  })], 1) : _vm._e(), this.form.discountRuleMobile === 4 && this.form.platforms === 1 || this.form.discountRuleMobile === 4 && this.form.platforms === 2 ? _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Lower Tier To")]), _c('SelectType', {
    attrs: {
      "options": _vm.tierLevelOptions,
      "default-text": 'Lowering Tier Level'
    },
    model: {
      value: _vm.form.tierLevel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tierLevel", $$v);
      },
      expression: "form.tierLevel"
    }
  })], 1) : _vm._e(), _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-2"
  }, [_vm._v("Offer Type")]), _c('SelectType', {
    attrs: {
      "options": _vm.offerTypes,
      "default-text": 'Offer Type',
      "disabled": true
    },
    model: {
      value: _vm.form.offerType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "offerType", $$v);
      },
      expression: "form.offerType"
    }
  })], 1)], 1) : _vm._e(), _vm.form.platforms === 4 ? _c('div', [_c('div', {
    staticClass: "grid gap-16 grid-cols-2"
  }, [_c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Discount Type")]), _c('SelectType', {
    attrs: {
      "options": _vm.discountTypes,
      "default-text": 'Select Discount Type',
      "disabled": true
    },
    model: {
      value: _vm.form.discountType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "discountType", $$v);
      },
      expression: "form.discountType"
    }
  }), _c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Payment Gateway")]), _c('MultiselectCustom', {
    attrs: {
      "options": _vm.paymentGatewaysOptions,
      "isSearchEnabled": true,
      "disabled": true,
      "default-text": 'Select Discount Rule ',
      "isMultiSelectActive": true
    },
    model: {
      value: _vm.form.paymentGateways,
      callback: function ($$v) {
        _vm.$set(_vm.form, "paymentGateways", $$v);
      },
      expression: "form.paymentGateways"
    }
  }), _c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("BIN Codes (Optional)")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Select Bin Codes",
      "open-direction": "bottom",
      "options": _vm.binCodesOptions,
      "multiple": true,
      "searchable": true,
      "close-on-select": false,
      "required": "",
      "disabled": true
    },
    model: {
      value: _vm.form.binCodes,
      callback: function ($$v) {
        _vm.$set(_vm.form, "binCodes", $$v);
      },
      expression: "form.binCodes"
    }
  }), _c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Discount Rule")]), _c('div', {
    staticClass: "grid gap-4 grid-cols-2"
  }, [_c('div', [_c('SelectType', {
    attrs: {
      "options": _vm.discountRules.filter(function (rule) {
        return rule.value !== 4;
      }),
      "default-text": 'Select Discount Rule'
    },
    model: {
      value: _vm.form.discountRule,
      callback: function ($$v) {
        _vm.$set(_vm.form, "discountRule", $$v);
      },
      expression: "form.discountRule"
    }
  })], 1), _vm.form.discountRule ? _c('div', [_vm.form.discountRule === 1 ? _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.discountAmount,
      expression: "form.discountAmount"
    }],
    staticClass: "bg-gray-50 py-2.5 px-2 border rounded-lg w-full",
    attrs: {
      "placeholder": "e.g., 2000",
      "required": "",
      "type": "number"
    },
    domProps: {
      "value": _vm.form.discountAmount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "discountAmount", $event.target.value);
      }
    }
  })]) : _vm._e(), _vm.form.discountRule === 2 ? _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.discountPercentage,
      expression: "form.discountPercentage"
    }],
    staticClass: "bg-gray-50 py-2.5 px-2 border rounded-lg w-full",
    attrs: {
      "min": "0",
      "step": "0.1",
      "max": "100",
      "placeholder": "e.g., 20",
      "required": "",
      "type": "number"
    },
    domProps: {
      "value": _vm.form.discountPercentage
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "discountPercentage", $event.target.value);
      }
    }
  })]) : _vm._e(), _vm.form.discountRule === 3 ? _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.discountFlushPrice,
      expression: "form.discountFlushPrice"
    }],
    staticClass: "bg-gray-50 py-2.5 px-2 border rounded-lg w-full",
    attrs: {
      "placeholder": "e.g., 2000",
      "required": "",
      "type": "number"
    },
    domProps: {
      "value": _vm.form.discountFlushPrice
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "discountFlushPrice", $event.target.value);
      }
    }
  })]) : _vm._e()]) : _vm._e()])], 1), _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-3"
  }, [_vm._v("Order Value (USD)")]), _c('div', {
    staticClass: "grid gap-4 grid-cols-2"
  }, [_c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-0 mb-2"
  }, [_vm._v("Minimum Order")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.form.minUSDPrice,
      expression: "form.minUSDPrice",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "bg-gray-50 py-2 px-2 border rounded-lg w-full",
    attrs: {
      "disabled": true,
      "type": "number",
      "step": "any",
      "placeholder": "Enter Minimum Order",
      "min": 0,
      "required": ""
    },
    domProps: {
      "value": _vm.form.minUSDPrice
    },
    on: {
      "update": function ($event) {
        return _vm.showPreviewFormat($event, 'minUsd', 'usd');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "minUSDPrice", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "formatMinUsd"
    }
  }, [_vm._v(" " + _vm._s(this.previewFormat.minUsd))])]), _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-0 mb-2"
  }, [_vm._v("Maximum Order")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.form.maxUSDPrice,
      expression: "form.maxUSDPrice",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "bg-gray-50 py-2 px-2 border rounded-lg w-full",
    attrs: {
      "disabled": true,
      "type": "number",
      "step": "any",
      "placeholder": "Enter Maximum Order",
      "min": 0,
      "required": ""
    },
    domProps: {
      "value": _vm.form.maxUSDPrice
    },
    on: {
      "update": function ($event) {
        return _vm.showPreviewFormat($event, 'maxUsd', 'usd');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "maxUSDPrice", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "formatMaxUsd"
    }
  }, [_vm._v(" " + _vm._s(this.previewFormat.maxUsd))])]), _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-0 mb-3"
  }, [_vm._v("Order Value (IDR)")]), _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-0 mb-2"
  }, [_vm._v("Minimum Order")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.form.minIDRPrice,
      expression: "form.minIDRPrice",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "bg-gray-50 py-2 px-2 border rounded-lg w-full",
    attrs: {
      "disabled": true,
      "type": "number",
      "step": "any",
      "placeholder": "Enter Minimum Order",
      "min": 0,
      "required": ""
    },
    domProps: {
      "value": _vm.form.minIDRPrice
    },
    on: {
      "update": function ($event) {
        return _vm.showPreviewFormat($event, 'minIdr', 'idr');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "minIDRPrice", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "formatMaxIdr"
    }
  }, [_vm._v(" " + _vm._s(this.previewFormat.minIdr))])])]), _c('div', {
    staticClass: "mt-9"
  }, [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-0 mb-2"
  }, [_vm._v("Maximum Order")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.form.maxIDRPrice,
      expression: "form.maxIDRPrice",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "bg-gray-50 py-2 px-2 border rounded-lg w-full",
    attrs: {
      "disabled": true,
      "type": "number",
      "step": "any",
      "placeholder": "Enter Maximum Order",
      "min": 0,
      "required": ""
    },
    domProps: {
      "value": _vm.form.maxIDRPrice
    },
    on: {
      "update": function ($event) {
        return _vm.showPreviewFormat($event, 'maxIdr', 'idr');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "maxIDRPrice", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "formatMaxIdr"
    }
  }, [_vm._v(" " + _vm._s(this.previewFormat.maxIdr))])])]), _c('div', {
    staticClass: "flex-col justify-start items-start"
  }, [_c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-2"
  }, [_vm._v("Offer Type")]), _c('SelectType', {
    attrs: {
      "options": _vm.offerTypes,
      "default-text": 'Offer Type',
      "disabled": true
    },
    model: {
      value: _vm.form.offerType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "offerType", $$v);
      },
      expression: "form.offerType"
    }
  })], 1), _vm.form.offerType === 2 ? _c('div', [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-2"
  }, [_vm._v("General Offer ")]), _c('SelectType', {
    attrs: {
      "options": _vm.generalOffers,
      "default-text": 'Select General Offer'
    },
    model: {
      value: _vm.form.generalOffer,
      callback: function ($$v) {
        _vm.$set(_vm.form, "generalOffer", $$v);
      },
      expression: "form.generalOffer"
    }
  })], 1) : _vm._e(), _vm.form.generalOffer == 10 && _vm.form.offerType === 2 || _vm.form.generalOffer == 11 && _vm.form.offerType === 2 || _vm.form.generalOffer == 12 && _vm.form.offerType === 2 || _vm.form.generalOffer == 13 && _vm.form.offerType === 2 ? _c('div', {
    attrs: {
      "label": "Publisher"
    }
  }, [_c('label', {
    staticClass: "text-gray-600 text-sm font-semibold block mt-3 mb-2"
  }, [_vm._v("Publisher")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Type Publisher",
      "open-direction": "bottom",
      "options": _vm.publisherSearchOptions,
      "searchable": true,
      "loading": _vm.isPublisherSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": false
    },
    on: {
      "search-change": _vm.publisherFind
    },
    model: {
      value: _vm.form.publisher,
      callback: function ($$v) {
        _vm.$set(_vm.form, "publisher", $$v);
      },
      expression: "form.publisher"
    }
  })], 1) : _vm._e()])])])]) : _vm._e()]) : _vm._e()]), _vm.currentTab === 3 && _vm.form.offerType === 1 ? _c('div', {
    staticClass: "bg-white mt-6 px-4 py-4 rounded-lg shadow-sm"
  }, [_c('div', {
    staticClass: "flex justify-end items-center"
  }, [_c('div', {}, [_c('button', {
    staticClass: "border rounded-lg py-2.5 px-2.5 mb-4 bg-white",
    on: {
      "click": _vm.addBulk
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-plus"
  }), _vm._v(" Add Bulk")]), _c('t-modal', {
    ref: "AddBulkModal"
  }, [_c('div', {
    staticClass: "text-sm flex-col text-left mb-3"
  }, [_c('label', {
    staticClass: "font-semibold"
  }, [_vm._v("Offer Name")]), _c('p', {
    staticClass: "text-gray-500"
  }, [_vm._v("Enter the offer you want to include in the list.")])]), _c('t-textarea', {
    staticClass: "rounded-lg mb-4",
    attrs: {
      "type": "number",
      "placeholder": "Eg: 708243, 708243, 708243"
    },
    on: {
      "change": _vm.searchByNumberOffers
    },
    model: {
      value: _vm.form.arrayIdOffers,
      callback: function ($$v) {
        _vm.$set(_vm.form, "arrayIdOffers", $$v);
      },
      expression: "form.arrayIdOffers"
    }
  }), _c('div', {
    staticClass: "flex justify-center"
  }, [_c('button', {
    staticClass: "bg-blue-700 py-2.5 px-2.5 w-full text-white rounded-lg",
    on: {
      "click": _vm.addBulkId
    }
  }, [_vm._v("Submit")])])], 1)], 1)]), _c('table', {
    staticClass: "min-w-full divide-y divide-gray-200"
  }, [_c('thead', {
    staticClass: "bg-gray-50"
  }, [_c('tr', [_c('th', {
    staticClass: "px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
  }, [_vm._v("Act")]), _c('th', {
    staticClass: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  }, [_vm._v("No")]), _c('th', {
    staticClass: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  }, [_vm._v("Offer Name or id")]), _vm.form.pricingScheme === 2 && _vm.form.platforms === 1 ? _c('th', {
    staticClass: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  }, [_vm._v("Apple Promotional Code")]) : _vm._e(), _vm.form.pricingScheme === 2 && _vm.form.platforms === 2 ? _c('th', {
    staticClass: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  }, [_vm._v("Android Promotional Code")]) : _vm._e(), _c('th', {
    staticClass: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  }, [_vm._v("original price ")]), _c('th', {
    staticClass: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  }, [_vm._v("After discount price")]), _c('th', {
    staticClass: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  }, [_vm._v("discount percentage")])])]), _c('tbody', {
    staticClass: "bg-white divide-y divide-gray-200"
  }, [_c('tr', [_c('td'), _c('td'), _c('td', {
    staticClass: "text-center py-3 px-4",
    attrs: {
      "colspan": "1"
    }
  }, [_c('div', {
    staticClass: "flex justify-start w-full"
  }, [_c('div', {
    staticClass: "block w-96"
  }, [_c('button', {
    staticClass: "text-center border-custom bg-white w-full text-blue-500 font-semibold px-0 py-2 rounded-lg",
    on: {
      "click": _vm.addNewRow
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-plus mr-2"
  }), _vm._v(" Add New ")])])])])]), _vm._l(_vm.selectOnDataTable || [], function (item, index) {
    var _item$selectedOfferNa;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "px-6 py-1 text-center"
    }, [_c('i', {
      staticClass: "fa-solid fa-trash text-gray-500 cursor-pointer",
      on: {
        "click": function ($event) {
          return _vm.deleteRow(index);
        }
      }
    })]), _c('td', {
      staticClass: "px-6 py-1 text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "px-6 py-1 w-96",
      attrs: {
        "title": ((_item$selectedOfferNa = item.selectedOfferName) === null || _item$selectedOfferNa === void 0 ? void 0 : _item$selectedOfferNa.name) || ''
      }
    }, [_c('multiselect', {
      staticClass: "w-96 discount-offers",
      attrs: {
        "label": "name",
        "track-by": "id",
        "placeholder": "Enter Offer Name",
        "open-direction": "bottom",
        "options": _vm.bulkOffersSearchOptions,
        "loading": _vm.isbulkOffersSearch,
        "searchable": true,
        "close-on-select": true,
        "multiple": false
      },
      on: {
        "input": function ($event) {
          return _vm.onOfferSelect($event, index);
        },
        "search-change": _vm.bulkOfferFind
      },
      scopedSlots: _vm._u([{
        key: "option",
        fn: function (_ref) {
          var option = _ref.option,
            index = _ref.index;
          return [_c('div', {
            key: index,
            staticClass: "custom-option",
            attrs: {
              "title": option.name || ''
            }
          }, [_vm._v(" " + _vm._s(option.name) + " ")])];
        }
      }], null, true),
      model: {
        value: item.selectedOfferName,
        callback: function ($$v) {
          _vm.$set(item, "selectedOfferName", $$v);
        },
        expression: "item.selectedOfferName"
      }
    })], 1), _vm.form.pricingScheme === 2 && _vm.form.platforms === 1 || _vm.form.pricingScheme === 2 && _vm.form.platforms === 2 ? _c('td', [_c('span', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.inputPremiumCode,
        expression: "item.inputPremiumCode"
      }],
      staticClass: "bg-gray-50 py-2 px-2 border rounded-lg w-full",
      attrs: {
        "type": "text",
        "step": "any",
        "placeholder": "Input Code"
      },
      domProps: {
        "value": item.inputPremiumCode
      },
      on: {
        "input": [function ($event) {
          if ($event.target.composing) { return; }
          _vm.$set(item, "inputPremiumCode", $event.target.value);
        }, function ($event) {
          return _vm.codePremium($event, index);
        }]
      }
    })])]) : _vm._e(), _c('td', {
      staticClass: "px-6 py-1"
    }, [_vm._v(" " + _vm._s(item.price_original) + " ")]), _c('td', {
      staticClass: "px-6 py-1"
    }, [_vm.form.pricingScheme === 2 && _vm.form.platforms === 1 || _vm.form.pricingScheme === 2 && _vm.form.platforms === 2 ? _c('span', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.price_discountPremium,
        expression: "item.price_discountPremium"
      }],
      staticClass: "bg-gray-50 py-2 px-2 border rounded-lg w-full",
      attrs: {
        "type": "number",
        "step": "any",
        "placeholder": "Input After Discount Price"
      },
      domProps: {
        "value": item.price_discountPremium
      },
      on: {
        "input": [function ($event) {
          if ($event.target.composing) { return; }
          _vm.$set(item, "price_discountPremium", $event.target.value);
        }, function ($event) {
          return _vm.afterDiscoundPremium($event, item, index);
        }]
      }
    })]) : _c('span', {
      class: {
        'text-red-500': item.price_discount > item.price_original || item.price_discount === 0
      }
    }, [_vm._v(" " + _vm._s(item.price_discount > item.price_original ? 0 : item.price_discount) + " ")])]), _c('td', {
      staticClass: "px-6 py-1"
    }, [_vm.form.pricingScheme === 2 && _vm.form.platforms === 1 || _vm.form.pricingScheme === 2 && _vm.form.platforms === 2 ? _c('span', [_vm._v(" " + _vm._s(item.discount_percentagePremium) + "% ")]) : _c('span', {
      class: {
        'text-red-500': item.price_discount > item.price_original || item.price_discount === 0
      }
    }, [_vm._v(" " + _vm._s(item.price_discount > item.price_original ? 0 : item.discount_percentage) + " % ")])])]);
  })], 2)])]) : _vm._e(), _c('div', {
    staticClass: "tab-navigation mt-6 flex justify-start space-x-2 mb-4"
  }, [_vm.currentTab === 3 ? _c('button', {
    staticClass: "bg-blue-600 text-white px-4 py-2 rounded",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v("Save")]) : _vm._e(), _vm.currentTab === 1 || _vm.currentTab === 2 ? _c('button', {
    staticClass: "bg-blue-600 text-white px-4 py-2 rounded",
    on: {
      "click": _vm.nextTab
    }
  }, [_vm._v("Next")]) : _vm._e(), _c('button', {
    staticClass: "bg-white text-gray-500 px-4 py-2 rounded",
    class: {
      'hidden': _vm.currentTab === 1
    },
    on: {
      "click": _vm.previousTab
    }
  }, [_vm._v("Back")]), _c('button', {
    staticClass: "bg-white text-gray-500 px-4 py-2 rounded",
    class: {
      'hidden': _vm.currentTab === 2 || _vm.currentTab === 3
    },
    on: {
      "click": _vm.backOnListDiscount
    }
  }, [_vm._v("Back")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }