var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative text-left w-full"
  }, [_c('button', {
    class: ['inline-flex w-full justify-between rounded-md border px-4 py-2.5 text-sm font-medium shadow-sm focus:outline-none focus:ring-2', _vm.isError ? 'bg-red-300 border-red-500 text-red-600' : _vm.disabled ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500'],
    attrs: {
      "type": "button",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.toggleDropdown
    }
  }, [_c('span', {
    class: _vm.isError ? 'text-red-500' : ''
  }, [_vm._v(_vm._s(_vm.selectedOptionText))]), _c('svg', {
    staticClass: "ml-2 -mr-1 h-5 w-5",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M19 9l-7 7-7-7"
    }
  })])]), _vm.isOpen && !_vm.disabled ? _c('div', {
    staticClass: "absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  }, [_vm.isSearchEnabled ? _c('div', {
    staticClass: "p-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchQuery,
      expression: "searchQuery"
    }],
    staticClass: "w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500",
    attrs: {
      "type": "text",
      "placeholder": "Search Content Type..."
    },
    domProps: {
      "value": _vm.searchQuery
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.searchQuery = $event.target.value;
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "p-1 max-h-96 overflow-y-auto"
  }, _vm._l(_vm.filteredOptions, function (option) {
    return _c('label', {
      key: option.value,
      staticClass: "flex items-center p-2 cursor-pointer hover:bg-gray-100"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedValues,
        expression: "selectedValues"
      }],
      staticClass: "form-checkbox h-4 w-4 text-blue-600",
      attrs: {
        "type": "checkbox",
        "disabled": _vm.disabled
      },
      domProps: {
        "value": option.value,
        "checked": Array.isArray(_vm.selectedValues) ? _vm._i(_vm.selectedValues, option.value) > -1 : _vm.selectedValues
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.selectedValues,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = option.value,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.selectedValues = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedValues = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedValues = $$c;
          }
        }, _vm.handleChange]
      }
    }), _c('span', {
      staticClass: "ml-2 text-gray-700"
    }, [_vm._v(_vm._s(option.text))])]);
  }), 0)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }