<template>
    <div class="relative text-left w-full">
      <button
        type="button"
        @click="toggleDropdown"
        :disabled="disabled"
        :class="[
          'inline-flex w-full justify-between rounded-md border px-4 py-2.5 text-sm font-medium shadow-sm focus:outline-none focus:ring-2',
          isError ? 'bg-red-300 border-red-500 text-red-600' : 
          disabled ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500'
        ]"
      >
        <span :class="isError ? 'text-red-500' : ''">{{ selectedOptionText }}</span>
        <svg class="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
  
      <!-- Dropdown Menu -->
      <div v-if="isOpen && !disabled" class="absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <!-- Search Input (only shown if isSearchEnabled is true) -->
        <div v-if="isSearchEnabled" class="p-2">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Search Content Type..."
            class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
          />
        </div>
        
        <!-- Options List -->
        <div class="p-1 max-h-96 overflow-y-auto">
          <label
            v-for="option in filteredOptions"
            :key="option.value"
            class="flex items-center p-2 cursor-pointer hover:bg-gray-100"
          >
            <input
              type="checkbox" 
              :value="option.value"
              v-model="selectedValues"
              @change="handleChange"
              class="form-checkbox h-4 w-4 text-blue-600"
              :disabled="disabled"
            />
            <span class="ml-2 text-gray-700">{{ option.text }}</span>
          </label>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SelectType',
    props: {
      value: [Array],
      options: Array,
      defaultText: {
        type: String,
        default: 'Select Content Type'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isError: {
        type: Boolean,
        default: false
      },
      isSearchEnabled: { // Boolean prop untuk kontrol pencarian
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isOpen: false,
        selectedValues: this.value || [], // Inisialisasi dengan array kosong atau nilai yang ada
        contentTypeOptions: this.options,
        searchQuery: '' // Menyimpan input pencarian
      };
    },
    computed: {
      selectedOptionText() {
        if (this.selectedValues.length > 0) {
          return this.selectedValues
            .map(value => {
              const option = this.contentTypeOptions.find(option => option.value === value);
              return option ? option.text : '';
            })
            .join(', ');
        }
        return this.defaultText;
      },
      filteredOptions() {
        // Memfilter opsi berdasarkan searchQuery jika isSearchEnabled diaktifkan
        if (this.isSearchEnabled) {
          return this.contentTypeOptions.filter(option => 
            option.text.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }
        return this.contentTypeOptions;
      }
    },
    methods: {
      toggleDropdown() {
        if (!this.disabled) {
          this.isOpen = !this.isOpen;
        }
        if (this.isError) {
          this.$emit('update:isError', false);
        }
      },
      handleChange() {
        this.isOpen = false;
        this.$emit('input', this.selectedValues); 
        this.clearSearch();
      },
      handleClickOutside(event) {
        if (this.isOpen && !this.$el.contains(event.target)) {
          this.isOpen = false;
        }
      },
      clearSearch() {
        // Bersihkan searchQuery ketika opsi sudah dipilih
        this.searchQuery = '';
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside);
    },
    watch: {
      value(newValue) {
        this.selectedValues = newValue || [];
      }
    }
  };
  </script>
  