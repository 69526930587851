<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="bg-white p-4 my-4 rounded-lg shadow-sm">
      <label> <span @click='cancel()' class="text-capitalize cursor-pointer text-gray-900 font-semibold">
          Discount</span> <i class="fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"></i> <span
          class="text-gray-500 font-normal capitalize"> Edit </span>
      </label>
      <h4 class="text-xl capitalize">Edit Discount</h4>
    </div>
    <div class="tab-container">
      <div class="tab-header flex items-center space-x-4  bg-white rounded-lg shadow-sm py-3 px-4 mb-4">
        <div :class="['tab-item flex items-center', { 'text-blue-500 font-semibold': currentTab === 1 }]"
         >
          <span
            :class="['font-bold rounded-full border p-1.5 mr-2 text-center w-9 h-9', { 'border-custom': currentTab === 1 }]">1</span>
          General
        </div>
        <span class="text-gray-400">»</span>
        <div :class="['tab-item flex items-center', { 'text-blue-500 font-semibold': currentTab === 2 }]"
         >
          <span
            :class="['font-bold rounded-full border p-1.5 mr-2 text-center w-9 h-9', { 'border-custom': currentTab === 2 }]">2</span>
          Platform
        </div>
        <span class="text-gray-400">»</span>
        <div :class="['tab-item flex items-center', { 'text-blue-500 font-semibold': currentTab === 3 }]"
          >
          <span
            :class="['font-bold rounded-full border p-1.5 mr-2 text-center w-9 h-9', { 'border-custom': currentTab === 3 }]">3</span>
          Detail
        </div>
      </div>

      <div class="tab-content mt-6 px-4 py-4">
        <div v-if="currentTab === 1">
          <h5 class="text-gray-900 font-bold text-xl">Edit Discount</h5>
          <div class="flex-col w-1/2 mt-3 pr-4">
            <label class="text-gray-600 text-sm font-semibold mb-3 block">Discount Name</label>
            <input v-model="form.name" id="discount-Name" placeholder="Enter Discount Name" requiredtype="text"
              :disabled="true"
              :class="true == true ? 'opacity-60 bg-gray-50 py-2.5 px-2 border rounded-lg w-full' : 'bg-gray-50 py-2.5 px-2 border rounded-lg w-full'" />

            <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Campaign Name</label>
            <multiselect v-model="form.campaign" label="name" track-by="name" placeholder="Type Campaign"
              open-direction="bottom" :options="campaignSearchOptions" :searchable="true" :loading="isCampaignSearch"
              :close-on-select="true" :options-limit="10" :multiple="false" @search-change="campaignFind"
              :disabled="true">
            </multiselect>
            <DatetimeCustom :timeOnlyHours="true"  class="mt-3" :label="'Valid From'" v-model="form.valid_from"></DatetimeCustom>
            <DatetimeCustom :timeOnlyHours="true"  class="mt-3" :label="'Valid To'" v-model="form.valid_to"></DatetimeCustom>
            <label class="text-gray-600 text-sm font-semibold mb-3 mt-3 block">Partner Participation (Optional)</label>
            <SelectType v-model="form.partnerParticipation" :options="partnerParticipations" :disabled="true"
              :default-text="'Enter Partner Participation'" />
            <label class="text-gray-600 text-sm font-semibold mb-3 mt-3 block">Sales Recognition (Optional)</label>
            <SelectType v-model="form.salesRecognition" :options="salesRecognitions" :disabled="true"
              :default-text="'Enter Sales Recognition'" />
            <label class="text-gray-600 text-sm font-semibold mb-3 mt-3 block">Vendor Participation (Optional)</label>
            <SelectType v-model="form.vendorParticipation" :options="vendorParticipations" :disabled="true"
              :default-text="'Enter Sales Recognition'" />
            <label class="text-gray-600 text-sm font-semibold mb-3 mt-3 block">Discount Activation Status</label>
         
          <div>
            <button
              type="button"
              @click="toggleActive"
              :class="[
                'w-12 h-6 button-active-inactive rounded-full relative transition-colors duration-200 focus:outline-none',
                form.is_active === true ? 'bg-blue-700' : 'bg-gray-300'
              ]"
            >
              <span
                :class="[
                  'w-5 h-5 bg-white rounded-full shadow transform transition-transform duration-200',
                  form.is_active === true ?  'translate-x-6' : 'translate-x-0'
                ]"
              >
              
              </span>
            </button>
          </div>
          </div>
        </div>
        <div v-if="currentTab === 2" :key="currentTab">
          <label class="text-gray-600 text-sm font-semibold mb-3 mt-3 block">Platform</label>
          <div class="w-1/2">
            <SelectType v-model="form.platforms" :options="platformsOptions" :disabled="true"
              :default-text="'Select Platform'" />

          </div>
          <div v-if="form.platforms === 1 || form.platforms === 2">
            <div class="w-1/2 mt-3">
              <label class="text-gray-600 text-sm font-semibold mb-3 mt-3 block">Pricing Scheme</label>
              <SelectType v-model="form.pricingScheme" :options="pricingSchemeOptions" :default-text="'Pricing Scheme'"
                :disabled="true" />
            </div>
          </div>
        </div>
        <div v-if="currentTab === 3">
          <h4 class="text-xl font-semibold">Edit New Discount</h4>
          <div class="w-1/2" v-if="form.platforms === 1 || form.platforms === 2">
            <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Type</label>
            <SelectType v-model="form.discountType" :options="discountTypes" :default-text="'Select Discount Type'"
              :disabled="true" />
            <div v-if="this.form.platforms === 1">
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Payment Gateway</label>
              <MultiselectCustom v-model="form.paymentGateways" :options="paymentGatewaysOptions"
                :isSearchEnabled="true" :disabled="true" :default-text="'Select Discount Rule '"
                :isMultiSelectActive="true" />
            </div>
            <div v-else>
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Payment Gateway</label>
              <MultiselectCustom v-model="form.paymentGateways" :options="paymentGatewaysOptions"
                :isSearchEnabled="true" :default-text="'Select Discount Rule '" :isMultiSelectActive="true"
                :disabled="true" />
            </div>
            <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Rule</label>
            <SelectType v-model="form.discountRuleMobile" :options="discountRuleMobileOptions"
              :disabled="form.pricingScheme === 2" :default-text="'Select Discount Rule '" />

            <div v-if="this.form.discountRuleMobile === 3 && this.form.pricingScheme === 1 && this.form.platforms === 1">
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Tier</label>
              <multiselect v-model='form.ios_tier' label='tier_code' track-by='tier_code' placeholder='Ios Tier'
                open-direction='bottom' :options='iosTierSearchOptions' :searchable='true' :close-on-select='true'
                :multiple='false' @search-change='iosTierFind'>
              </multiselect>
            </div>
            <div
              v-if="this.form.discountRuleMobile === 3 && this.form.pricingScheme === 1 && this.form.platforms === 2">
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Tier</label>
              <multiselect v-model='form.android_tier' label='tier_code' track-by='tier_code' placeholder='Android Tier'
                open-direction='bottom' :options='androidSearchOptions' :searchable='true' :close-on-select='true'
                :multiple='false' @search-change='androidTierTierFind'>
              </multiselect>
            </div>
            <div
              v-if="this.form.discountRuleMobile === 4 && this.form.platforms === 1 || this.form.discountRuleMobile === 4 && this.form.platforms === 2">
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Lower Tier To</label>

              <SelectType v-model="form.tierLevel" :options="tierLevelOptions" :default-text="'Lowering Tier Level'" />
            </div>
            <div>
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-2">Offer Type</label>
              <SelectType v-model="form.offerType" :options="offerTypes" :default-text="'Offer Type'"
                :disabled="true" />

            </div>
          </div>
          <!-- <div v-if="form.pricingScheme === 2 && form.platforms !== 4">ini Premium</div> -->
          <div v-if="form.platforms === 4">
            <div class="grid gap-16 grid-cols-2">
              <div>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Type</label>
                <SelectType v-model="form.discountType" :options="discountTypes" :default-text="'Select Discount Type'"
                  :disabled="true" />
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Payment Gateway</label>
                <!-- <multiselect v-model="form.paymentGateways" label="name" track-by="name"
                  placeholder="Select Payment Gateway" 
                  open-direction="bottom" 
                  :options="paymentGatewaysOptions"
                  :multiple="true" :searchable="true" 
                  :close-on-select="false" required
                  :disabled="true"
                   /> -->
                <MultiselectCustom v-model="form.paymentGateways" :options="paymentGatewaysOptions"
                  :isSearchEnabled="true" :disabled="true" :default-text="'Select Discount Rule '"
                  :isMultiSelectActive="true" />
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">BIN Codes (Optional)</label>
                <multiselect v-model="form.binCodes" label="name" track-by="name" placeholder="Select Bin Codes"
                  open-direction="bottom" :options="binCodesOptions" :multiple="true" :searchable="true"
                  :close-on-select="false" required :disabled="true">
                </multiselect>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Rule</label>
                <div class="grid gap-4 grid-cols-2">
                  <div>
                    <SelectType v-model="form.discountRule" :options="discountRules.filter(rule => rule.value !== 4)"
                      :default-text="'Select Discount Rule'" />
                  </div>
                  <div v-if="form.discountRule">
                    <div v-if="form.discountRule === 1">
                      <input v-model="form.discountAmount" placeholder="e.g., 2000" required type="number"
                        class="bg-gray-50 py-2.5 px-2 border rounded-lg w-full" />
                    </div>
                    <div v-if="form.discountRule === 2">
                      <input v-model="form.discountPercentage" min="0" step="0.1" max="100" placeholder="e.g., 20"
                        required type="number" class="bg-gray-50 py-2.5 px-2 border rounded-lg w-full" />

                    </div>

                    <div v-if="form.discountRule === 3">
                      <input v-model="form.discountFlushPrice" placeholder="e.g., 2000" required type="number"
                        class="bg-gray-50 py-2.5 px-2 border rounded-lg w-full" />

                    </div>

                  </div>
                </div>

              </div>
              <div>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Order Value (USD)</label>
                <div class="grid gap-4 grid-cols-2">
                  <div>
                    <label class="text-gray-600 text-sm font-semibold block mt-0 mb-2">Minimum Order</label>
                    <input v-model.number="form.minUSDPrice" :disabled="true" type="number" step="any"
                      placeholder="Enter Minimum Order" :min="0" @update="showPreviewFormat($event, 'minUsd', 'usd')"
                      class="bg-gray-50 py-2 px-2 border rounded-lg w-full" required />
                    <small id="formatMinUsd" class="form-text text-muted"> {{ this.previewFormat.minUsd }}</small>
                  </div>
                  <div>
                    <label class="text-gray-600 text-sm font-semibold block mt-0 mb-2">Maximum Order</label>
                    <input v-model.number="form.maxUSDPrice" :disabled="true" type="number" step="any"
                      class="bg-gray-50 py-2 px-2 border rounded-lg w-full" placeholder="Enter Maximum Order" :min="0"
                      @update="showPreviewFormat($event, 'maxUsd', 'usd')" required />
                    <small id="formatMaxUsd" class="form-text text-muted"> {{ this.previewFormat.maxUsd }}</small>
                  </div>
                  <div>
                    <label class="text-gray-600 text-sm font-semibold block mt-0 mb-3">Order Value (IDR)</label>
                    <div>
                      <label class="text-gray-600 text-sm font-semibold block mt-0 mb-2">Minimum Order</label>
                      <input :disabled="true" v-model.number="form.minIDRPrice" type="number" step="any"
                        class="bg-gray-50 py-2 px-2 border rounded-lg w-full" placeholder="Enter Minimum Order" :min="0"
                        @update="showPreviewFormat($event, 'minIdr', 'idr')" required />
                      <small id="formatMaxIdr" class="form-text text-muted"> {{ this.previewFormat.minIdr }}</small>
                    </div>
                  </div>
                  <div class="mt-9">
                    <label class="text-gray-600 text-sm font-semibold block mt-0 mb-2">Maximum Order</label>
                    <input :disabled="true" v-model.number="form.maxIDRPrice" type="number" step="any"
                      class="bg-gray-50 py-2 px-2 border rounded-lg w-full" placeholder="Enter Maximum Order" :min="0"
                      @update="showPreviewFormat($event, 'maxIdr', 'idr')" required />
                    <small id="formatMaxIdr" class="form-text text-muted"> {{ this.previewFormat.maxIdr }}</small>
                  </div>
                </div>
                <div class="flex-col justify-start items-start">
                  <div>
                    <label class="text-gray-600 text-sm font-semibold block mt-3 mb-2">Offer Type</label>
                    <SelectType v-model="form.offerType" :options="offerTypes" :default-text="'Offer Type'"
                      :disabled="true" />

                  </div>
                  <div v-if="form.offerType === 2">
                    <label class="text-gray-600 text-sm font-semibold block mt-3 mb-2">General Offer </label>
                    <SelectType v-model="form.generalOffer" :options="generalOffers"
                      :default-text="'Select General Offer'" />

                  </div>
                  <div label="Publisher" v-if="form.generalOffer == 10 && form.offerType === 2
                    || form.generalOffer == 11
                    && form.offerType === 2 ||
                    form.generalOffer == 12 && form.offerType === 2
                    || form.generalOffer == 13 && form.offerType === 2">
                    <label class="text-gray-600 text-sm font-semibold block mt-3 mb-2">Publisher</label>

                    <multiselect v-model="form.publisher" label="name" track-by="name" placeholder="Type Publisher"
                      open-direction="bottom" :options="publisherSearchOptions" :searchable="true"
                      :loading="isPublisherSearch" :close-on-select="true" :options-limit="10" :multiple="false"
                      @search-change="publisherFind">
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white mt-6 px-4 py-4  rounded-lg shadow-sm" v-if="currentTab === 3 && form.offerType === 1">
        <div class="flex justify-end items-center">
          <div class="">
            <button class="border rounded-lg py-2.5 px-2.5 mb-4 bg-white" @click="addBulk"><i
                class="fa-solid fa-plus"></i>
              Add Bulk</button>
            <t-modal ref="AddBulkModal">
              <div class="text-sm flex-col text-left mb-3">
                <label class="font-semibold">Offer Name</label>
                <p class="text-gray-500">Enter the offer you want to include in the list.</p>
              </div>
              <t-textarea type="number" class="rounded-lg mb-4" placeholder="Eg: 708243, 708243, 708243"
                v-model="form.arrayIdOffers" @change="searchByNumberOffers"></t-textarea>
              <div class="flex justify-center">
                <button class="bg-blue-700 py-2.5 px-2.5 w-full text-white rounded-lg"
                  @click="addBulkId">Submit</button>
              </div>
            </t-modal>
          </div>
        </div>
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Act</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Offer Name or
                id</th>
              <th v-if="form.pricingScheme === 2 && form.platforms === 1"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Apple Promotional
                Code</th>
                <th v-if="form.pricingScheme === 2 && form.platforms === 2"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Android Promotional
                Code</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">original price
              </th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">After discount
                price</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">discount
                percentage</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr>
              <td></td>
              <td></td>
              <td colspan="1" class="text-center py-3 px-4">
                <div class="flex justify-start w-full">
                  <div class="block w-96">
                    <button @click="addNewRow"
                      class="text-center border-custom bg-white w-full text-blue-500 font-semibold px-0 py-2 rounded-lg">
                      <i class="fa-solid fa-plus mr-2"></i> Add New
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-for="(item, index) in selectOnDataTable || []" :key="index">

              <td class="px-6 py-1 text-center "><i class="fa-solid fa-trash text-gray-500 cursor-pointer"
                  @click="deleteRow(index)"></i></td>
              <td class="px-6 py-1 text-center ">{{ index + 1 }}</td>
              <td class="px-6 py-1 w-96" :title="item.selectedOfferName?.name || ''">
                <multiselect 
                class="w-96 discount-offers" 
                v-model="item.selectedOfferName" 
                label="name" 
                track-by="id"
                placeholder="Enter Offer Name" 
                open-direction="bottom" 
                :options="bulkOffersSearchOptions"
                :loading="isbulkOffersSearch" 
                :searchable="true" 
                :close-on-select="true" 
                :multiple="false"
                @input="onOfferSelect($event, index)" 
                @search-change="bulkOfferFind"
              >
                <template #option="{ option, index }">
                  <div class="custom-option" :key="index"  :title="option.name || ''">
                    {{ option.name }}
                  </div>
                </template>
              </multiselect>
              </td>
              <td v-if="form.pricingScheme === 2 && form.platforms === 1 || form.pricingScheme === 2 && form.platforms === 2">
                <span>
                  <input v-model="item.inputPremiumCode" type="text" step="any" @input="codePremium($event, index)"
                    class="bg-gray-50 py-2 px-2 border rounded-lg w-full" placeholder="Input Code" />
                </span>
              </td>
              <td class="px-6 py-1 ">
                {{ item.price_original }}
              </td>
              <td class="px-6 py-1 ">

                <span v-if="form.pricingScheme === 2 && form.platforms === 1 || form.pricingScheme === 2 && form.platforms === 2">
                  <input v-model="item.price_discountPremium" type="number" step="any"
                    class="bg-gray-50 py-2 px-2 border rounded-lg w-full" placeholder="Input After Discount Price"
                    @input="afterDiscoundPremium($event, item, index)" />
                </span>
                <span :class="{'text-red-500': item.price_discount > item.price_original || item.price_discount === 0}" v-else>
                  {{ item.price_discount > item.price_original ?  0  : item.price_discount }}
                </span>
              </td>
              <td class="px-6 py-1 ">
                <span v-if="form.pricingScheme === 2 && form.platforms === 1 || form.pricingScheme === 2 && form.platforms === 2">
                  {{ item.discount_percentagePremium }}%
                </span>
                  <span :class="{'text-red-500': item.price_discount > item.price_original || item.price_discount === 0}" v-else>
                  {{ item.price_discount > item.price_original ?  0  : item.discount_percentage }} %
                </span>
              </td>
            </tr>

          </tbody>
        </table>
      </div>

      <div class="tab-navigation mt-6 flex justify-start space-x-2 mb-4">
        <button v-if="currentTab === 3" @click="onSubmit" class="bg-blue-600 text-white px-4 py-2 rounded">Save</button>
        <button v-if="currentTab === 1 || currentTab === 2" @click="nextTab"
          class="bg-blue-600 text-white px-4 py-2 rounded">Next</button>
        <button  :class="{'hidden':currentTab === 1}" @click="previousTab" class="bg-white text-gray-500 px-4 py-2 rounded">Back</button>
        <button :class="{'hidden' :currentTab === 2 || currentTab === 3 }" @click="backOnListDiscount" class="bg-white text-gray-500 px-4 py-2 rounded">Back</button>
    
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import constant from "../../store/constant";
import moment from "moment";
import moment_timezone from 'moment-timezone'
import DatetimeCustom from '../../components/form/Datetime.vue'
import SelectType from '../../components/form/ContentTypeSelector.vue';
import MultiselectCustom from '../../components/form/MultiSelectedCustom.vue'
import { EventBus } from '../../../src/event-bus'
const PARTNER_PARTICIPATION = Object.entries(constant.PARTNER_PARTICIPATION).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});

const DISCOUNT_TYPES = Object.entries(constant.DISCOUNT_TYPES).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});

const DISCOUNT_PREDEFINED_GROUP = Object.entries(constant.DISCOUNT_PREDEFINED_GROUP).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});

const OFFER_DISCOUNT_RULE = Object.entries(constant.OFFER_DISCOUNT_RULE).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const PLATFORM_PROMO = Object.entries(constant.PLATFORM_PROMO).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});

export default {
  name: "updateDiscountOffer",
  components: {
    DatetimeCustom,
    SelectType,
    MultiselectCustom
  },
  data() {
    return {
      form: {
        campaign: "",
        name: "",
        salesRecognition: null,
        partnerParticipation: null,
        vendorParticipation: null,
        discountType: null,
        platforms: null,
        AllIosTier: [],
        AllAndroidTier: [],
        paymentGateways: [],
        discountRule: null,
        // trialPromotions: '0 days',
        discountRuleMobile: 0,
        binCodes: [],
        discount_usd: 0,
        discount_idr: 0,
        premiumCode: null,
        discountPremium: null,
        discountAmount: 0,
        ios_tier: null,
        android_tier: null,
        discountPercentage: 0,
        discountFlushPrice: 0,
        minUSDPrice: 0,
        tierLevel: 0,
        point: 0,
        maxUSDPrice: 0,
        minIDRPrice: 0,
        maxIDRPrice: 0,
        offerType: null,
        generalOffer: null,
        specifiedOffer: "",
        valid_from: "",
        valid_to: "",
        valid_from_detail: "",
        valid_to_detail: "",
        publisher: [],
        arrayIdOffers: "",
        is_active: false,
        min: moment().format('YYYY-MM-DD hh:mm A'),
      },
      timezone: '',
      currentTab: 1,
      pricingScheme: null,
      arrayIdOffersResult: [],
      selectOnDataTable: [],
      salesRecognitions: [
        { value: null, text: 'Select Sales Recognitions' },
        { value: 1, text: 'Recognize as Normal Sales' },
        { value: 2, text: 'Recognize as other incomes' },
      ],
      pricingSchemeOptions: [
        { value: 1, text: 'Single' },
        { value: 2, text: 'Premium' },
      ],
      partnerParticipations: [
        { value: null, text: 'Select Partner Participations' },
        ...PARTNER_PARTICIPATION,
      ],
      vendorParticipations: [
        { value: null, text: 'Select Vendor Participations' },
        ...PARTNER_PARTICIPATION,
      ],
      discountTypes: [
        { value: null, text: 'Select Discount Type' },
        ...DISCOUNT_TYPES
      ],
      discountRules: [
        ...OFFER_DISCOUNT_RULE,
      ],
      platformsOptions: [
        ...PLATFORM_PROMO,
      ],
      // trialPromotions: [
      //   { value: '0 days', text: 'Select Trial Day' },
      //   ...TRIALS,
      // ],
      offerTypes: [
        { value: 1, text: 'Specified Offer' },
        { value: 2, text: 'General Offer' },
      ],
      generalOffers: [
        { value: null, text: 'Select Predifined Group' },
        ...DISCOUNT_PREDEFINED_GROUP,
      ],
      discountRuleMobileOptions: [
        { value: 3, text: 'Flush Price' },
        { value: 4, text: 'Lowering Tier Level' },
      ],
      previewFormat: {
        discIdr: "",
        discUsd: "",
        minUsd: "",
        maxUsd: "",
        minIdr: "",
        maxIdr: "",
      },
      show: true,
      campaignSearchOptions: [],
      isCampaignSearch: false,
      publisherSearchOptions: [],
      isPublisherSearch: false,
      bulkOffersSearchOptions: [],
      isbulkOffersSearch: false,
      iosTierSearchOptions: [],
      androidSearchOptions: [],
      tierLevelOptions: [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10' },
      ],
      debounce: null,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.discounts.isError,
      isLoading: (state) => state.discounts.isLoading,
      errorMessage: (state) => state.discounts.errorMessage,
      successMessage: (state) => state.discounts.successMessage,
      item: (state) => state.discounts.item,
      iosTier: (state) => state.discounts.iosTier,
      androidTier: (state) => state.discounts.androidTier
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      EventBus.$emit('show-toast', {
        message: this.errorMessage,
        type: 'success',
      });
      
    },

    successMessage: function () {
    if (!this.successMessage) return;

    EventBus.$emit('show-toast', {
      message: this.successMessage,
      type: 'success',
    });

    if (this.isLoading) {
      this.$store.commit('discounts/setLoading', false);
    }
  },

    item: function () {
      if (!Object.keys(this.item).length) return;
      this.setEditDiscount();
    },
    'form.discountAmount'() {
      this.isDiscountAmountError = false;
      this.updateDiscountPrices()
    },
    'form.discountPercentage'() {
      this.updateDiscountPrices()
    },
    'form.discountFlushPrice'() {
      this.updateDiscountPrices()
    },
    'form.discountRuleMobile'() {
      // this.isoDiscountRuleMobileError = false
      this.updateDiscountPrices()
    },
    'form.tierLevel'() {
      this.updateDiscountPrices()
    },
    'form.ios_tier'() {
      const selectedTier = this.form.ios_tier;

      if (selectedTier) {
        this.updateDiscountPrices();
      } else {
        console.log("Tier belum dipilih atau price_idr kosong");
      }
    },
    'form.android_tier'() {
      const selectedTier = this.form.android_tier;

      if (selectedTier) {
        this.updateDiscountPrices();
      } else {
        console.log("Tier belum dipilih atau price_idr kosong");
      }
    }

  },
  mounted() {
   
  },
   created() {
    let id = this.$route.params.id;
    this.item_id = id;
    this.paymentGatewaysOptions = Object.entries(constant.PAYMENT_GATEWAY).map((item) => {
      return {
        text: item[0],
        value: item[1],
      }
    });
    this.binCodesOptions = constant.ACTIVE_BIN.map((item) => {
      return {
        name: item.name,
        value: item.value,
      }
    });
    this.fetchDiscountsDetailByid(id);
     this.fetchDiscountsTiercodeIos()
     this.fetchDiscountsTiercodeAndroid()

  },
  methods: {
    ...mapActions("campaign", ["searchCampaign"]),
    ...mapActions("discounts", [
      "discountUpdate",
      "fetchDiscountsByid",
      "fetchDiscountsDetailByid",
      "searchVendors",
      "fetchDiscountsPlatformsid",
      "fetchCampaignById",
      "fetchDiscountsPaymentsid",
      "fetchDiscountsOffersid",
      "fetchDiscountsVendorid",
      "searchBulkyOffersByNumber",
      "searchBulkyOffers",
      "fetchDiscountsTiercodeIos",
      "fetchDiscountsTiercodeAndroid",
      "fetchDiscountsTiercodeIosSearch",
      "fetchDiscountsTiercodeAndroidSearch",
      "fetchVendorsId",
    ]),
    nextTab() {
      if (this.currentTab < 3) {
        this.currentTab++
        this.fetchDiscountsTiercodeIos()
        this.fetchDiscountsTiercodeAndroid()
      }
    },
    previousTab() {
      if (this.currentTab > 1) {
        this.currentTab--
      }
    },
    backOnListDiscount () {
    this.$router.push({
      path: `/discounts`,
    });
  },
    goToTab(tabNumber) {
      this.currentTab = tabNumber
    },
    toggleActive() {
      this.form.is_active = !this.form.is_active;
    },

    onSubmit(event) {
      event.preventDefault();
      
      const data = this.form;
      const objectDiscountRule = [
          data.discountAmount,
          data.discountFlushPrice,
          data.discountPercentage,
        ];
      const payload = {
        id: this.$route.params.id,
        name: data.name,
        campaign_id: data.campaign.length ? data.campaign.map(item => item.id) : data.campaign.id,
        sales_recognition: data.salesRecognition,
        partner_participation: data.partnerParticipation,
        vendor_participation: data.vendorParticipation,
        platforms: [data.platforms],
        discount_type: data.discountType,
        payment_gateways: data.paymentGateways,
        discount_rule: data.platforms[0] === 4 ? data.discountRule : data.discountRuleMobile,
        bin_codes: data.binCodes.map(item => item.value),
        discount_usd: data.discount_usd ?? 0,
        discount_tier: data.platforms === 1 ? data.ios_tier?.tier_code  : data.platforms === 2 ? data.android_tier?.tier_code : null,
        discount_idr : this.form.platforms === 4
        ? objectDiscountRule.filter(item => item !== null && item !== undefined && item !== 0 && item !== "0").join(", ") || "0"
        : data.ios_tier?.price_idr || data.android_tier?.price_idr || "0",
        lower_tier: data.tierLevel ?? null,
        min_usd_order_price: data.minUSDPrice ?? 0,
        max_usd_order_price: data.maxUSDPrice ?? 0,
        min_idr_order_price: data.minIDRPrice ?? 0,
        max_idr_order_price: data.maxIDRPrice ?? 0,
        offer_type: data.offerType,
        predefined_group: data.generalOffer,
        offers: this.selectOnDataTable.map((item) => ({
          offer_id: item.selectedOfferName.id,
          promotional_code: item.inputPremiumCode,
          discount_price: item.price_discountPremium,
        })),
        valid_from: (() => {
              const date = new Date(data.valid_from);
              date.setMinutes(0, 0, 0);
              return date.toISOString();
            })(),
            valid_to: (() => {
              const date = new Date(data.valid_to);
              date.setMinutes(0, 0, 0);
              return date.toISOString();
            })(),
        discount_point: data.point ?? 0,
        vendor_id: data.publisher.length ? data.publisher.map(item => item.id) : data.publisher.id,
        is_active: data.is_active 
      } 
      this.discountUpdate(payload);
    },
    formatDate(tgl) {
      return moment(tgl).format('YYYY-MM-DD HH:mm:ss');
    },
    formatingActive(today, fromDate, validToDate) {
      if (today >= fromDate && today <= validToDate) {
        return true
      }
      return today >= validToDate;
    },

    campaignFind(query) {
      if (!query) return;
      this.isCampaignSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchCampaign(payload).then((response) => {
          this.campaignSearchOptions = response.data.data.rows;
          this.isCampaignSearch = false;
        }).catch(() => {
          this.isCampaignSearch = false;
        });
      }, 600);
    },
    publisherFind(query) {
      if (!query) return;

      this.isPublisherSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchVendors(payload).then((response) => {
          this.publisherSearchOptions = response.data.data.rows;
          this.isPublisherSearch = false;
        }).catch(() => {
          this.isPublisherSearch = false;
        });
      }, 600);
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    codePremium(event, index) {
      const code = event.target.value;
      //this.form.premiumCode = code
      this.selectOnDataTable[index].inputPremiumCode = code;
    },

    afterDiscoundPremium(event, item, index) {
      const discount = parseFloat(event.target.value) || 0;
      this.selectOnDataTable[index].price_discountPremium = discount;

      if (discount > 0) {
        let discountPercentage = ((item.price_original - discount) / item.price_original) * 100;
        if (discountPercentage < 0) {
          discountPercentage = 0;
        } else if (discountPercentage > 100) {
          discountPercentage = 100;
        }

        item.discount_percentagePremium = discountPercentage.toFixed();
      } else {
        item.discount_percentagePremium = 0;
      }
    },
    async setEditDiscount() {
      const data = this.item;
      this.AllIosTier = await this.fetchDiscountsTiercodeIos();
      this.AllAndroidTier = await this.fetchDiscountsTiercodeAndroid()
      this.form.valid_from = moment(data.valid_from).format('YYYY-MM-DD hh:mm A');
      this.form.valid_to = moment(data.valid_to).format('YYYY-MM-DD hh:mm A');
      this.form.name = data.name;
      this.form.salesRecognition = data.sales_recognition;
      this.form.partnerParticipation = data.partner_participation;
      this.form.vendorParticipation = data.vendor_participation;
      this.form.tierLevel = data.lower_tier
      this.form.ios_tier = this.AllIosTier.find(
        (option) => option.tier_code === data.discount_tier
      ) || null;

      this.form.android_tier = this.AllAndroidTier.find(
        (option) => option.tier_code === data.discount_tier
      ) || null;

      this.form.discountFlushPrice = data.discount_rule === 3 ? data.discount_idr.replace(/\.00$/, "") : 0
      this.form.discountPercentage = data.discount_rule === 2 ? data.discount_idr.replace(/\.00$/, "") : 0
      this.form.discountAmount = data.discount_rule === 1 ? data.discount_idr.replace(/\.00$/, "") : 0
      this.form.discountType = data.discount_type;
      this.fetchCampaignById(data.campaign_id).then(response => {
        this.form.campaign = response.data?.data?.rows[0];
        this.vendorSearchOptions = response.data?.data?.rows;
      });
      this.fetchDiscountsPaymentsid(data.id).then(response => {
        const payments = [];
        const listPayments = response.data.data;

        listPayments.forEach(item => {
          payments.push(item.paymentgateway_id);
        });

        this.form.paymentGateways = this.paymentGatewaysOptions.filter(
          item => payments.includes(item.value)
        );
        const values = this.form.paymentGateways.map(item => item.value);
        this.form.paymentGateways = values
      });

      this.form.discountRule = data.discount_rule;
      this.form.discountRuleMobile = data.discount_rule
      this.form.platforms = data.platform_id[0]
      this.form.tierLevel = data.lower_tier ?? null
      
      this.selectOnDataTable = data.offers.map((offer) => ({
        selectedOfferName: {
          id: offer.id,
          name: offer.name,
          platform_offers: offer.platform_offers.map((platform) => ({
            id: platform.id,
            offer_id: platform.offer_id,
            tier_id: platform.tier_id,
            tier_code: platform.tier_code,
            platform_id: platform.platform_id,
            price_idr: parseFloat(platform.price_idr),
            price_usd: parseFloat(platform.price_usd),
          })),
        },
        price_original: parseFloat(offer.price_idr),
        price_discount: 0,
        discount_percentage: 0,
        price_discountPremium: parseFloat(offer.discount_price),
        discount_percentagePremium: 0,
        inputPremiumCode: offer.promotional_code,
      }));

      this.selectOnDataTable.forEach((item) => {
        const discount = item.price_discountPremium || 0;
        if (discount > 0) {
          item.discount_percentagePremium = ((item.price_original - discount) / item.price_original * 100).toFixed();
        }
      });
      this.form.binCodes = this.binCodesOptions.filter(item => [...data.bin_codes].includes(item.value));
      this.form.discount_usd = data.discount_usd;
      this.form.discount_idr = data.discount_idr;
      this.form.point = data.discount_point;
      this.form.pricingScheme = data.pricing_scheme
      this.form.minUSDPrice = data.min_usd_order_price;
      this.form.maxUSDPrice = data.max_usd_order_price;
      this.form.minIDRPrice = data.min_idr_order_price;
      this.form.maxIDRPrice = data.max_idr_order_price;
      this.form.offerType = (data.predefined_group != null) ? 2 : 1;
      this.form.generalOffer = data.predefined_group ?? null;
      this.fetchDiscountsOffersid(data.id).then(response => {
        const offers = response.data.data;
        const tempOffers = [];
        offers.map(item => {
          tempOffers.push(item.offer_id);
        });
        this.form.specifiedOffer = (data.predefined_group == null) ? tempOffers.sort().toString() : '';
      });
      this.form.valid_from_detail = moment_timezone.tz(data.valid_from, "Asia/Jakarta").format('YYYY-MM-DD HH:mm:ss');
      this.form.valid_to_detail = moment_timezone.tz(data.valid_to, "Asia/Jakarta").format('YYYY-MM-DD HH:mm:ss');
      this.fetchDiscountsVendorid(data.id).then(response => {
        if (response.data.data != null) {
          this.fetchVendorsId(response.data.data.vendor_id).then(result => {
            this.form.publisher = result.data?.data?.rows[0];
            this.publisherSearchOptions = result.data?.data?.rows;
          });
        }
      });
     // this.form.is_active = data.is_active ? 'true' : 'false';
      this.form.is_active = data.is_active
      this.previewFormat.discUsd = this.numberFormatUsd(data.discount_usd);
      this.previewFormat.discIdr = this.numberFormatIdr(data.discount_idr);
      this.previewFormat.minUsd = this.numberFormatUsd(data.min_usd_order_price);
      this.previewFormat.maxUsd = this.numberFormatUsd(data.max_usd_order_price);
      this.previewFormat.minIdr = this.numberFormatIdr(data.min_idr_order_price);
      this.previewFormat.maxIdr = this.numberFormatIdr(data.max_idr_order_price);

    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    numberFormatIdr(money) {
      return new Intl.NumberFormat(
        'id-ID',
        {
          style: 'currency',
          currency: 'IDR'
        }
      ).format(money);
    },
    numberFormatUsd(money) {
      return new Intl.NumberFormat(
        'en-US',
        {
          style: 'currency',
          currency: 'USD'
        }
      ).format(money);
    },
    iosTierFind(query) {
      if (!query) return;
      this.isIosTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchDiscountsTiercodeIosSearch({ q: query }).then((response) => {
          this.iosTierSearchOptions = response.data.data;
          this.isIosTierSearch = false;
        }).catch(() => {
          this.isIosTierSearch = false;
        });
      }, 600);
    },
    androidTierTierFind(query) {
      if (!query) return;
      this.isAndroidTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchDiscountsTiercodeAndroidSearch({ q: query }).then((response) => {
          this.androidSearchOptions = response.data.data;
          this.isAndroidTierSearch = false;
        }).catch(() => {
          this.isAndroidTierSearch = false;
        });
      }, 600);
    },
    showPreviewFormat(value, field, currency) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        const money = (value == null) ? 0 : value;
        switch (currency) {
          case 'idr':
            this.previewFormat[field] = this.numberFormatIdr(money);
            break;
          case 'usd':
            this.previewFormat[field] = this.numberFormatUsd(money);
            break;
          default:
            break;
        }
      }, 1000);
    },
    addBulk() {
      this.$refs.AddBulkModal.show();
    },
    deleteRow(index) {
      this.selectOnDataTable.splice(index, 1);
    },
    bulkOfferFind(query) {
      if (!query) return;
      this.isbulkOffersSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
          platform:this.form.platforms,
          ispremium:this.form.pricingScheme === 2 ? true: false,
        };
        this.searchBulkyOffers(payload).then((response) => {
          this.bulkOffersSearchOptions = response.data.data.rows;
          this.isbulkOffersSearch = false;
        }).catch(() => {
          this.isbulkOffersSearch = false;
        });
      }, 600);
    },
    addNewRow() {
      const newRow = {
        id: '', // Misalnya id-nya kosong pada awalnya
        name: '',
        selectedOfferName: [],
        price_discount: 0,
        inputPremiumCode: '',
        price_discountPremium: '',
        discount_percentage: 0,
        discount_percentagePremium: 0,
        created: new Date()
      };

      function isRowValid(row) {
        for (let key in row) {
          if (row[key] === '' || (Array.isArray(row[key]) && row[key].length === 0)) {
            return false;
          }
        }
        return true;
      }
      const hasEmptyId = this.selectOnDataTable.some(row => row.id === '');
      if (hasEmptyId) {
        console.log('Tidak bisa menambahkan row. ID kosong atau ID duplikat.');
        return;
      }
      this.selectOnDataTable.push(newRow);
      if (isRowValid(newRow)) {
        this.selectOnDataTable.push(newRow);
        this.updateDiscountPrices()
      }
    },
    onOfferSelect(selectedItem, rowIndex) {
  const isDuplicate = this.item.offers.some((item, index) => {
    return (
      item.id === selectedItem.id && 
      index !== rowIndex
    );
  });

  if (isDuplicate) {
    this.selectOnDataTable.splice(rowIndex, 1);
    EventBus.$emit('show-toast', {
                  message:`Item Id: ${selectedItem.id} have already been added.`,
                  type: 'error',
                  });
  }

  if (selectedItem && this.selectOnDataTable[rowIndex]) {
    this.selectOnDataTable[rowIndex].id = selectedItem.id;
    this.selectOnDataTable[rowIndex].name = selectedItem.name;
    this.selectOnDataTable[rowIndex].price_original = selectedItem.price_idr
      ? selectedItem.price_idr.replace(/\.00$/, "")
      : selectedItem.price_idr;
    this.selectOnDataTable[rowIndex].inputPremiumCode = selectedItem.inputPremiumCode;
    this.selectOnDataTable[rowIndex].price_discountPremium = selectedItem.price_discountPremium;
    this.selectOnDataTable[rowIndex].discount_percentagePremium = selectedItem.discount_percentagePremium;

    this.updateDiscountPrices();
  } 
},
    searchByNumberOffers() {
      this.arrayIdOffersResult = this.form.arrayIdOffers
        .split(",")
        .map((value) => value.trim())
        .filter((value) => !isNaN(value) && value !== "")
        .map(Number);
    },
    addBulkId() {
      this.searchBulkyOffersByNumber(this.arrayIdOffersResult).then((response) => {
        response.data.data.offers.forEach((offer) => {
          const existingOffer = this.selectOnDataTable.find(item => item.id === offer.id);
          if (existingOffer) {
            console(`Item Id: ${offer.id} have already been added.`);
          } else {
            const newRow = {
              id: offer.id,
              name: offer.name,
              selectedOfferName: offer,
              price_original: offer.price_idr,
              price_discount: 0,
              discount_percentage: 0
            };
            this.selectOnDataTable.push(newRow);
            this.form.arrayIdOffers = ''
          }

        });
        this.updateDiscountPrices()

        this.$refs.AddBulkModal.hide();

      });

    },
  async  updateDiscountPrices() {
      this.selectOnDataTable.forEach(item => {
        let discountPercentage = 0;

        if (this.form.discountRule === 1) {
          this.form.discount_idr = item.price_original - this.form.discountAmount;

          if (this.form.discount_idr < 0) {
            this.form.discount_idr = 0;
          }
          if (this.form.discount_idr > 0) {
            discountPercentage = ((this.form.discountAmount / item.price_original) * 100).toFixed();
            if (discountPercentage > 100) {
              discountPercentage = 100;
            }
          }
        }


        if (this.form.discountRule === 2) {
          if (this.form.discount_idr < 0) {
            this.form.discount_idr = 0;
          }
          if (this.form.discountPercentage < 0) {
            this.form.discountPercentage = 0;
          } else if (this.form.discountPercentage > 100) {
            this.form.discountPercentage = 100;
          }
          if (this.form.discountPercentage === '' || this.form.discountPercentage === null || this.form.discountPercentage === 0) {
            this.form.discountPercentage = 0;
          }


          this.form.discountPercentage = parseFloat(this.form.discountPercentage);
          let percentageUsing = 100 - this.form.discountPercentage;
          if (this.form.discount_idr > 0 || this.form.discountPercentage > 0) {
            this.form.discount_idr = Math.round((percentageUsing / 100) * item.price_original).toFixed();
            discountPercentage = this.form.discountPercentage;
          }

        }

        if (this.form.discountRule === 3) {
          this.form.discount_idr = Number(this.form.discountFlushPrice);

          if (this.form.discount_idr < 0) {
            this.form.discount_idr = 0;
          }

          if (this.form.discount_idr > item.price_original) {
            this.form.discount_idr = item.price_original;
          }

          if (this.form.discount_idr > 0) {
            discountPercentage = ((item.price_original - this.form.discount_idr) / item.price_original * 100).toFixed();
            if (discountPercentage > 100) {
              discountPercentage = 100;
            }
          }
        }

        if (this.form.platforms !== 4) {
          item.selectedOfferName.platform_offers.forEach((offer) => {
            if (this.form.discountRuleMobile === 3 && this.form.discountRuleMobile !== 4) {
              const iosTier = this.form.ios_tier;
              const androidTier = this.form.android_tier;

              if (iosTier && iosTier.price_idr || androidTier && androidTier.price_idr) {

                this.form.discount_idr = this.form.platforms === 1 ? parseFloat(iosTier.price_idr) : parseFloat(androidTier.price_idr);
                if (parseFloat(this.form.discount_idr) > parseFloat(item.price_original)) {
                  EventBus.$emit('show-toast', {
                    message: 'Harga discount harus lebih kecil dari harga normal',
                    type: 'error',
                  });
                 // this.form.discount_idr = 'Harga discount harus lebih kecil dari harga normal'
                }


                if (this.form.discount_idr > 0) {
                  if (this.form.discount_idr > item.price_original) {
                    discountPercentage = '';
                  } else {
                    discountPercentage = ((item.price_original - this.form.discount_idr) / item.price_original * 100).toFixed();
                    if (discountPercentage > 100) {
                      discountPercentage = 100;
                    }
                  }
                } else {
                  discountPercentage = '';
                }

              }
            }

            if (this.form.discountRuleMobile === 4 && this.form.discountRuleMobile !== 3) {
              this.AllIosTier.forEach((iosTierItem) => {
                if (offer.tier_code === iosTierItem.tier_code && offer.platform_id === 1) {
                  const resultCalculte = iosTierItem.level_tier - this.form.tierLevel;
                  const finalResult = resultCalculte < 1 ? 1 : resultCalculte
                  const objectTier = this.AllIosTier[finalResult - 1]

                  this.form.discount_idr = this.form.tierLevel !== 0 ? objectTier.price_idr : objectTier.price_idr
                  if (parseFloat(this.form.discount_idr) > parseFloat(item.price_original)) {
                    EventBus.$emit('show-toast', {
                      message: 'Discount price must be lower than the normal price',
                      type: 'error',
                    });

                    this.form.discount_idr = 0
                    return;

                  } else {
                    const discountPrice = item.price_original - this.form.discount_idr
                    const persatagePrice = this.form.tierLevel !== 0 ? ((discountPrice / item.price_original) * 100).toFixed() : 0

                    if (persatagePrice === '100') {
                      discountPercentage = '0';
                    } else {
                      discountPercentage = this.form.tierLevel !== 0 ? (item.price_original - this.form.discount_idr) / item.price_original * 100 : 0
                      discountPercentage = discountPercentage.toFixed();
                    }
                  }
                }
              });

              this.AllAndroidTier.forEach((androidTier) => {
                if (offer.tier_code === androidTier.tier_code && offer.platform_id === 2) {
                  const resultCalculte = androidTier.level_tier - this.form.tierLevel;
                  const finalResult = resultCalculte < 1 ? 1 : resultCalculte
                  const objectTier = this.AllAndroidTier[finalResult - 1]
                  this.form.discount_idr = this.form.tierLevel !== 0 ? objectTier.price_idr : objectTier.price_idr
                  if (parseFloat(this.form.discount_idr) > parseFloat(item.price_original)) {
                    EventBus.$emit('show-toast', {
                      message: 'Discount price must be lower than the normal price',
                      type: 'error',
                    });
                    this.form.discount_idr = 0
                    return;

                  } else {
                    const discountPrice = item.price_original - this.form.discount_idr
                    const persatagePrice = this.form.tierLevel !== 0 ? ((discountPrice / item.price_original) * 100).toFixed() : 0

                    if (persatagePrice === '100') {
                      discountPercentage = '0';
                    } else {
                      discountPercentage = this.form.tierLevel !== 0 ? (item.price_original - this.form.discount_idr) / item.price_original * 100 : 0
                      discountPercentage = discountPercentage.toFixed();
                    }
                  }
                }
              });
            }

          });

        }
        item.price_discount = this.form.discount_idr;
        item.discount_percentage = discountPercentage;

      });
    },
  }
};
</script>
<style scoped>
.button-active-inactive {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.border-custom {
  border: 1px solid #3b82f6 !important;
}
</style>